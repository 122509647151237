.timer-card {
    width: 500px;
    height: 250px;
    background-color: #1b262c;
    box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    animation: pulse 10s infinite;
  }
  
  .custom-modal .modal-content {
    background-color: #1b262c;
  }
  
  .custom-modal label{
    color:#fff;
    font-family: 'Open Sans', sans-serif;
  }
  
  .custom-modal legend{
    color:#fff;
    font-family: 'Open Sans', sans-serif;
  }
  
  .text-white {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
  
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
    50% {
      box-shadow: 0 40px 100px rgba(131, 74, 0, 0.3);
    }
    100% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
  }
  
.contact-email {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
  transition: all 0.3s ease;
}

.contact-email:hover {
  color: #de1738;
  text-decoration: none;
}

.contact-email-holder {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

@media screen and (max-width: 600px) {
  .contact-email-holder {
    position: absolute !important;
    bottom: 50px !important;
    right: 15px !important;
  }
}

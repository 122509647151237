.app-logo-link {
 
}

.app-logo {
  width: 50px;
  height: 50px;
}

.app-logo-holder {
  position: absolute;
  top: 50px;
  left: 50px;
}

@media screen and (max-width: 600px) {
  .app-logo-holder {
    position: absolute !important;
    top: 50px !important;
    left: 0px !important;
  }
}

.circle-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
}

.red-circle {
  background-color: #de1738;
  opacity: 1;
  border: 1px solid #fff;
}

.white-circle {
  background-color: #fff;
}

.circle-dot.red-circle.current {
  animation: fade 2s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222831;
}

.icon-button {
  width: 50px;
  height: 50px;
}

.icon-button img {
  width: 20px;
  height: 20px;
}


.timer-text {
  font-family: "Anonymous Pro", monospace;
  font-weight: 700;
  font-size: 70px;
  color: #fff;
  line-height: 1;
}

.circle-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
}

.red-circle {
  background-color: #de1738;
  opacity: 1;
  border: 1px solid #fff;
}

.white-circle {
  background-color: #fff;
}

.circle-dot.red-circle.current {
  -webkit-animation: fade 2s infinite;
          animation: fade 2s infinite;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #de1738;
  border-color: #de1738;
}


.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(222,23,56,.25);
}

.form-check{
    padding-left: 0 !important;
}
.contact-email {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
  transition: all 0.3s ease;
}

.contact-email:hover {
  color: #de1738;
  text-decoration: none;
}

.contact-email-holder {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

@media screen and (max-width: 600px) {
  .contact-email-holder {
    position: absolute !important;
    bottom: 50px !important;
    right: 15px !important;
  }
}

.app-logo-link {
 
}

.app-logo {
  width: 50px;
  height: 50px;
}

.app-logo-holder {
  position: absolute;
  top: 50px;
  left: 50px;
}

@media screen and (max-width: 600px) {
  .app-logo-holder {
    position: absolute !important;
    top: 50px !important;
    left: 0px !important;
  }
}

.timer-card {
    width: 500px;
    height: 250px;
    background-color: #1b262c;
    box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    -webkit-animation: pulse 10s infinite;
            animation: pulse 10s infinite;
  }
  
  .custom-modal .modal-content {
    background-color: #1b262c;
  }
  
  .custom-modal label{
    color:#fff;
    font-family: 'Open Sans', sans-serif;
  }
  
  .custom-modal legend{
    color:#fff;
    font-family: 'Open Sans', sans-serif;
  }
  
  .text-white {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
  
  }
  
  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
    50% {
      box-shadow: 0 40px 100px rgba(131, 74, 0, 0.3);
    }
    100% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
    50% {
      box-shadow: 0 40px 100px rgba(131, 74, 0, 0.3);
    }
    100% {
      box-shadow: 0 20px 50px rgba(59, 43, 91, 0.7);
    }
  }
  

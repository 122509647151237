.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #de1738;
  border-color: #de1738;
}


.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(222,23,56,.25);
}

.form-check{
    padding-left: 0 !important;
}